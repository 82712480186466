var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component"
  }, [_c('v-layout', {
    staticClass: "member-login",
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "height": "100%"
    }
  }, [_c('v-layout', {
    staticClass: "px-4 py-8",
    attrs: {
      "justify-center": "",
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "auto",
      "max-width": "480"
    }
  }, [_c('v-card', {
    staticClass: "ma-lg-3",
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "title justify-center py-6"
  }, [_vm._v("Login")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "px-4 py-6 pa-lg-8"
  }, [_c('v-text-field', {
    attrs: {
      "label": "아이디",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('v-password-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "비밀번호",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login();
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "width": "100%",
      "x-large": "",
      "dark": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.login();
      }
    }
  }, [_vm._v("로그인")]), _c('v-btn', {
    staticClass: "mt-2 mt-lg-4",
    attrs: {
      "width": "100%",
      "x-large": "",
      "outlined": "",
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "flex-column flex-sm-row align-center px-6"
  }, [_c('v-checkbox', {
    staticClass: "mt-0 pa-0",
    attrs: {
      "label": "아이디 저장",
      "hide-details": ""
    },
    model: {
      value: _vm.isRemember,
      callback: function ($$v) {
        _vm.isRemember = $$v;
      },
      expression: "isRemember"
    }
  }), _c('v-spacer'), _c('v-btn', {
    staticClass: "mt-1 mt-sm-0",
    attrs: {
      "text": "",
      "tile": "",
      "to": "/member/idpw"
    }
  }, [_vm._v("아이디/비밀번호 찾기")])], 1)], 1)], 1)], 1)], 1), _c('kcp-cert', {
    ref: "kcpCert"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }