<template>
    <component :is="template">
        <v-layout fill-height class="member-login">
            <v-responsive height="100%">
                <v-layout justify-center align-center fill-height class="px-4 py-8">
                    <v-responsive width="100%" height="auto" max-width="480" >
                        <v-card rounded="lg" class="ma-lg-3">
                            <v-card-title class="title justify-center py-6">Login</v-card-title>
                            <v-divider/>
                            <v-card-text class="px-4 py-6 pa-lg-8">
                                <v-text-field v-model="username" label="아이디" persistent-placeholder hide-details />
                                <v-password-field v-model="password" label="비밀번호" persistent-placeholder hide-details class="mt-4" @keydown.enter="login()" />
                                <v-btn width="100%" x-large dark color="primary" class="mt-4" @click="login()">로그인</v-btn>
                                <v-btn width="100%" x-large outlined class="mt-2 mt-lg-4" to="/join">회원가입</v-btn>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions class="flex-column flex-sm-row align-center px-6">
                                <v-checkbox v-model="isRemember" label="아이디 저장" hide-details class="mt-0 pa-0"/>
                                <v-spacer />
                                <v-btn text tile to="/member/idpw" class="mt-1 mt-sm-0">아이디/비밀번호 찾기</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-responsive>
                </v-layout>
            </v-responsive>
            <kcp-cert ref="kcpCert"/>
        </v-layout>
    </component>
</template>
<script>
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import api from '@/api';
export default {
    components: {
        VPasswordField,
        KcpCert
    },
    data(){
        return {
            username: this.$cookies.get("username") || null,
            password: null,

            isRemember: false,
            memeber : {
                ci: null,
                name: null,
                birthday: null
            }
        };
    },
    methods: {
        validate(){
            try{
                if(!this.username) throw new Error("아이디를 입력해주세요");
                if(!this.password) throw new Error("비밀번호를 등록해주세요");
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async login(){
            try{
                if(this.validate()){
                    await this.$store.dispatch("login", {
                        username: this.username,
                        password: this.password
                    });

                    if(this.isRemember) this.$cookies.set("username", this.username);
        
                    this.$router.go(-1);

                }
            }
            catch(error){
                alert(error?.response?.data?.message ?? error.message);
                if(error?.response?.data?.message === '최초 1회 본인인증을 진행 후 서비스이용이 가능합니다.') {
                    this.$refs.kcpCert.$on("input", async (payload) => {
                        Object.keys(payload).forEach(key => {
                            this.memeber[key] = payload[key];
                        });
                        let { member } = await api.v1.members.verify({ member: this.memeber });
                        member.ci === null ? alert("인증에 실패했습니다.") : alert("인증에 성공했습니다. 다시 로그인 해주시기 바랍니다.")
                    });
                    this.$refs.kcpCert.open();
                    console.log(this.$refs, "dddd");
                }
            }
        },
        async onVerify(payload){
            Object.keys(payload).forEach(key => {
                this.memeber[key] = payload[key];
            });
            await api.v1.members.verify({ member: this.memeber});
        }
    },
    computed: {
        template(){
            return () => import(`@/templates/${this.$theme.pages.login}`);
        }
    }
}
</script>
<style scoped>
.member-login .title {
    font-size: 30px !important;
}
.member-login .background-layout {
    position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .member-login .title {
        font-size: 36px !important;
    }
}
@media (min-width:1200px){
}

</style>